import React from 'react';
import { commoditiesWrapperMixin } from '../styles/mixins';
import { DomesticSvg, InternationalSvg } from './SvgComponent';
import { Header } from './Header';
import { LabeledIcon, responsiveCss } from './LabeledIcon';

export function HomeContent({
    range,
    onDomesticClick,
    onInternationalClick,
}) {
    return (
        <>
            <Header>What kind of shipment do you need?</Header>
            <div css={commoditiesWrapperMixin}>
                <LabeledIcon
                    active={range === 'international'}
                    css={responsiveCss}
                    forwardedAs='button'
                    icon={InternationalSvg}
                    label='International'
                    onClick={onInternationalClick}
                />
                <LabeledIcon
                    active={range === 'domestic'}
                    css={responsiveCss}
                    forwardedAs='button'
                    icon={DomesticSvg}
                    label='Domestic (US)'
                    onClick={onDomesticClick}
                />
            </div>
        </>
    );
}
