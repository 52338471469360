// @ts-check
import React from 'react';

export const SvgComponent = (props) => {
    const {
        alt = '',
        role = 'presentation',
        src,
        staticFileName,
        ...imgProps
    } = props;

    let srcAttribute = src;
    if (staticFileName) {
        srcAttribute = `https://static.navegate.com/quote-module/${staticFileName}`;
    }

    return (
        <img
            alt={alt}
            role={role}
            src={srcAttribute}
            {...imgProps}
        />
    );
};

/* eslint-disable react/jsx-max-props-per-line */
export const AirSvg = (props) => <SvgComponent alt='Air' staticFileName='Air.svg' {...props} />;
export const AirFreightSvg = (props) => <SvgComponent alt='Air Freight' staticFileName='Air-Freight.svg' {...props} />;
export const AirPalletSvg = (props) => <SvgComponent alt='Air Pallet' staticFileName='Air-Pallet.svg' {...props} />;
export const CustomsSvg = (props) => <SvgComponent alt='Customs' staticFileName='Customs.svg' {...props} />;
export const DeliverySvg = (props) => <SvgComponent alt='Delivery' staticFileName='Delivery.svg' {...props} />;
export const DomesticSvg = (props) => <SvgComponent alt='Domestic' staticFileName='Domestic.svg' {...props} />;
export const EnvelopesSvg = (props) => <SvgComponent alt='Small Pack' staticFileName='smallpack-envelope.svg' {...props} />;
export const FortyPlusFootHcSvg = (props) => <SvgComponent alt='40 Foot High Cube' staticFileName='40-HC.svg' {...props} />;
export const FortyFootStdSvg = (props) => <SvgComponent alt='40 Foot Standard' staticFileName='40-STD.svg' {...props} />;
export const FlatbedSvg = (props) => <SvgComponent alt='Flatbed' staticFileName='Flatbed.svg' {...props} />;
export const FtlSvg = (props) => <SvgComponent alt='Full Truckload' staticFileName='FTL.svg' {...props} />;
export const InternationalSvg = (props) => <SvgComponent alt='International' staticFileName='International.svg' {...props} />;
export const LclSvg = (props) => <SvgComponent alt='Less than Container Load' staticFileName='Other-LCL-LTL.svg' {...props} />;
export const InsuranceSvg = (props) => <SvgComponent alt='Insurance' staticFileName='Insurance.svg' {...props} />;
export const LiftGate = (props) => <SvgComponent alt='Lift Gate' staticFileName='Lift-Gate.svg' {...props} />;
export const LimitedAccess = (props) => <SvgComponent alt='Limited Access' staticFileName='limited-access.svg' {...props} />;
export const LtlSvg = (props) => <SvgComponent alt='Less than Truckload' staticFileName='LTL.svg' {...props} />;
export const OceanSvg = (props) => <SvgComponent alt='Ocean' staticFileName='Ocean.svg' {...props} />;
export const OtherBoxesSvg = (props) => <SvgComponent alt='Other Boxes' staticFileName='Other-Boxes.svg' {...props} />;
export const OtherCommoditySvg = (props) => <SvgComponent alt='Other Commodity' staticFileName='Other-Commodity.svg' {...props} />;
export const OtherTruckSvg = (props) => <SvgComponent alt='Other Truck' staticFileName='Other-Truck.svg' {...props} />;
export const PalletSvg = (props) => <SvgComponent alt='Pallet' staticFileName='Pallet.svg' {...props} />;
export const PickupSvg = (props) => <SvgComponent alt='Pickup' staticFileName='Pickup.svg' {...props} />;
export const PouchesSvg = (props) => <SvgComponent alt='Pouches' staticFileName='smallpack-pouch.svg' {...props} />;
export const SmallParcelSvg = (props) => <SvgComponent alt='Small Parcel' staticFileName='Small-Parcel.svg' {...props} />;
export const Snowflake = (props) => <SvgComponent alt='Refrigerated' staticFileName='Snowflake.svg' {...props} />;
export const TwentyFootStdSvg = (props) => <SvgComponent alt='20 Foot Standard' staticFileName='20-STD.svg' {...props} />;
export const UldSvg = (props) => <SvgComponent alt='Unit Load Device' staticFileName='ULD.svg' {...props} />;
export const VanSvg = (props) => <SvgComponent alt='Van' staticFileName='Van.svg' {...props} />;
export const WarehouseSvg = (props) => <SvgComponent alt='Warehouse' staticFileName='Warehouse.svg' {...props} />;
