import styled, { css } from 'styled-components';
import { focus, gray5 } from '../../globals/colors';
import { fontSize } from '../../globals/fontSize';
import { breakpoints, responsivePropertyMixin } from '../../styles/mixins';
import alpha from '../../utility/alpha';

export const LabeledIconRoot = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 11em;
    min-height: 11em;
    border-radius: 2em;
    background: transparent;
    padding: 1em;
    box-shadow: ${({ active }) => active && '0 0 0 2px rgba(255, 255, 255, .5)'};
    cursor: pointer;
    user-select: none;

    &:hover, &:focus {
        background: ${alpha(gray5, .1)};
    }

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px ${focus};
    }
`;

const labeledIconSizeFn = ({ withLabel }) => withLabel ? '5em' : '7em';

export const LabeledIconSizer = styled.div`
    height: ${labeledIconSizeFn};
    display: flex;
    justify-content: center;

    & > * {
        height: 100%;
    }
`;

export const LabeledIconLabel = styled.span`
    font-size: inherit;
    font-weight: lighter;
    color: white;
    margin-top: ${({ iconExists }) => iconExists ? '1em' : 0};
    text-align: center;
    line-height: 1;
`;

export const responsiveCss = css`
    ${responsivePropertyMixin(breakpoints, [
        ['font-size', ...Object.values(fontSize.small)]
    ])}
`;
