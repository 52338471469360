// @ts-check
import React from 'react';
import { BannerHeading_FlavorText, BannerHeading_Root } from './BannerHeading.styles';

/** @typedef {{ text: string, url: string }} BannerHeadingProps */

/** @type {React.FC<BannerHeadingProps & React.HTMLAttributes<HTMLDivElement>>} */
export const BannerHeading = ({ children, text, url, ...otherProps }) => {

    return (
        <BannerHeading_Root {...otherProps}>
            <img
                alt={`${process.env.FORWARDER} Logo`}
                aria-label={`${process.env.FORWARDER} Logo`}
                src={url}
            />
            <BannerHeading_FlavorText dangerouslySetInnerHTML={{ __html: text }} />
        </BannerHeading_Root>
    );
};
