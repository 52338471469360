import { ContentfulClient } from 'emerald-ui/dist/Contentful';
import React, { useState } from 'react';
import { BannerHeading } from '../components/BannerHeading';
import { HomeContent } from '../components/HomeContent';
import { forwarderConfig } from '../globals/ForwarderConfig';
import { useStoredState } from '../hooks/useStoredState';
import { MainLayout } from '../layouts/MainLayout';
import { statePropertiesEnum } from '../utility/enums';
import { goToSummaryOrUrl } from '../utility/goToSummaryOrUrl';
import { useGuid } from '../utility/useGuid';

const nextUrl = '/modeOfTransportation';

function Home() {
    const showBanner = !useGuid();
    const [flavorText, setFlavorText] = useState('');

    ContentfulClient.getEntries({
        content_type: 'document',
        'fields.name': 'Quote Module Welcome Banner Text',
        'fields.forwarder': process.env.FORWARDER
    }).then((followUp) => {
        setFlavorText(followUp.items[0].fields.body || '');
    });

    const {
        storedState: {
            range = '',
        },
        setStoredState,
        keepSelectedStateProperties
    } = useStoredState();

    function setRangeAndRedirect(newRange) {
        if (newRange === range) {
            goToSummaryOrUrl(nextUrl);
        } else {
            setStoredState({ range: newRange });
            keepSelectedStateProperties([statePropertiesEnum.range, statePropertiesEnum.ready]);
            goToSummaryOrUrl(nextUrl);
        }
    }

    let banner;
    if (showBanner) {
        banner = (
            <BannerHeading
                text={flavorText}
                url={forwarderConfig[process.env.FORWARDER].logoSrc}
            />
        );
    }

    return (
        <MainLayout
            banner={banner}
            hideNextButton
            showLogo={false}
        >
            <HomeContent
                onDomesticClick={() => setRangeAndRedirect('domestic')}
                onInternationalClick={() => setRangeAndRedirect('international')}
                range={range}
            />
        </MainLayout>
    );
}

export default Home;
