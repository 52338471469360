export const containersEnum = {
    '20std': "20' standard",
    '40hc': "40' foot high cube",
    '40std': "40' foot standard",
    '45hc': "45' foot high cube",
    lcl: 'LCL',
    other: 'Other'
};

export const additionalServicesEnum = {
    customs: 'Customs',
    delivery: 'Delivery',
    pickup: 'Pickup',
    insurance: 'Insurance',
    liftGate: 'Lift gate',
    limitedAccess: 'Limited access',
    refrigeration: 'Refrigeration',
    warehouse: 'Warehouse'
};

export const trailersEnum = {
    flatbed: 'Flatbed',
    other: 'Other',
    van: 'Van'
};

export const commoditiesEnum = {
    APL: 'Air pallets',
    SMP: 'Small parcel',
    BOX: 'Boxes',
    ENV: 'Envelopes',
    OTH: 'Other',
    PLT: 'Pallets',
    PCH: 'Pouches',
    ULD: 'ULD'
};

export const statePropertiesEnum = {
    range: 'range',
    ready: 'ready',
    mot: 'modeOfTransportation',
    origin: 'origin',
    destination: 'destination',
    readyByDate: 'readyByDate',
    deliverByDate: 'deliverByDate',
    trailer: 'trailer',
    commodities: 'commodities',
    containers: 'containers',
    hazardous: 'hazardous',
    commodityDescription: 'commodityDescription',
    comments: 'comments',
    services: 'services',
    freightClass: 'freightClass'
};
