// @ts-check
import { accent0, gray0 } from 'emerald-ui/dist/globals/colors';
import { staticUrl } from 'emerald-ui/dist/globals/urls';
import styled from 'styled-components';

export const BannerHeading_Root = styled.div`
    background-color: ${gray0};
    background-image: url(${staticUrl('images/banner-welcome-background.jpg')});
    background-size: cover;
    background-position: left bottom;
    border-bottom: 2px solid ${accent0};
    padding: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        max-height: 12rem;
    }

    @media screen and (max-width: 767px) {
        padding: 2em;
        & + main {
            padding-top: 2em;
        }
    }
`;

export const BannerHeading_FlavorText = styled.p`
    color: white;
    text-align: center;
    margin-top: 1em;
    line-height: 1.25;
    letter-spacing: 0.02em;
    font-size: 24px;

    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
`;
