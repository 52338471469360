import Router from 'next/router';
import { storedStateKey } from '../hooks/useStoredState';

/**
 * This function checks for the 'summary' key in Session Storage. If it exists, we need to instead
 * push to the summary page. If this is the case, we also remove the summary key
 * before pushing. Otherwise, we just push to the url that was passed in.
 * @param {string} url the url to router.push to if summary doesn't exist
 */
export function goToSummaryOrUrl(url) {
    const { summary, ...otherState } = JSON.parse(sessionStorage.getItem(storedStateKey)) || {};
    if (summary) {
        sessionStorage.setItem(storedStateKey, JSON.stringify(otherState));
        Router.push('/summary');
    } else {
        Router.push(url);
    }
}
