import React from 'react';
import { LabeledIconLabel, LabeledIconRoot, LabeledIconSizer } from './LabeledIcon.styles';

export function LabeledIcon({
    label,
    icon: LabeledIconIcon,
    ...otherProps
}) {
    return (
        <LabeledIconRoot {...otherProps}>
            {LabeledIconIcon && (
                <LabeledIconSizer withLabel={!!label}>
                    <LabeledIconIcon />
                </LabeledIconSizer>
            )}
            {label && (
                <LabeledIconLabel iconExists={!!LabeledIconIcon}>{label}</LabeledIconLabel>
            )}
        </LabeledIconRoot>
    );
}
